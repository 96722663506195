import { Component, OnInit } from '@angular/core';

declare var require: any
var works: string;

@Component({
  selector: 'app-flesh',
  templateUrl: './flesh.component.html',
  styleUrls: ['./flesh.component.scss']
})
export class FleshComponent implements OnInit {

  // data = "data";
  works = require('../../../assets/data/data.json');

  constructor() { }

  ngOnInit() {
	  // console.log("Json data : ", JSON.stringify(works));
  }

}
