import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bone',
  templateUrl: './bone.component.html',
  styleUrls: ['./bone.component.scss']
})
export class BoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
