import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FleshComponent } from './views/flesh/flesh.component';
import { WorkComponent } from './views/work/work.component';
import { BoneComponent } from './views/bone/bone.component';
import { SilverComponent } from './views/silver/silver.component';
import { AshComponent } from './views/ash/ash.component';
import { DustComponent } from './views/dust/dust.component';


const routes: Routes = [
  { path: '', component: FleshComponent, pathMatch: 'full' },
  { path: 'flesh', component: FleshComponent },
  { path: 'flesh/:slug', component: WorkComponent },
  { path: 'bone', component: BoneComponent },
  { path: 'silver', component: SilverComponent },
  { path: 'ash', component: AshComponent },
  { path: 'dust', component: DustComponent },
  
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
