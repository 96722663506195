import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// external
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';
import { HttpClientModule } from '@angular/common/http';
import { EmbedVideo } from 'ngx-embed-video';


// internal
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './widgets/header/header.component';
import { DustComponent } from './views/dust/dust.component';
import { SilverComponent } from './views/silver/silver.component';
import { AshComponent } from './views/ash/ash.component';
import { BoneComponent } from './views/bone/bone.component';
import { FleshComponent } from './views/flesh/flesh.component';
import { CvComponent } from './widgets/cv/cv.component';
import { WorkComponent } from './views/work/work.component';

@NgModule({
  declarations: [
    AppComponent,
    DustComponent,
    HeaderComponent,
    SilverComponent,
    AshComponent,
    BoneComponent,
    FleshComponent,
    CvComponent,
    WorkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  	LightboxModule,
    HttpClientModule, 
    EmbedVideo.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
