import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Lightbox } from 'ngx-lightbox';
import { EmbedVideoService } from 'ngx-embed-video';

declare var require: any
var works: string;

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

	works = require('../../../assets/data/data.json');
	work;
	slug: string;
    private _albums: any[] = [];
	numimages: number; // ADDED for thumbnail resize
  
  vimeoEmbedString: string;

  constructor(
  	private route: ActivatedRoute,
  	private _lightbox: Lightbox,
    private embedService: EmbedVideoService
  ) {

	  this.slug = this.route.snapshot.paramMap.get("slug");
	  
	  for (let work of this.works) {
		  if (work.slug == this.slug) {
			  this.work = work;
		  }
	  }
	  
  	  for (let image of this.work['images']) {

          const src = 'assets/works/' + image.name;
          const caption = '' + image.caption;
          const thumb = 'assets/works/' + image.name;
          const album = {
             src: src,
             caption: caption,
             thumb: thumb
          };
 
          this._albums.push(album);
	  }
	  
	  this.numimages = this._albums.length; // ADDED for thumbnail resize
    
    if (this.work.vimeoID) {
      this.vimeoEmbedString = this.embedService.embed_vimeo(this.work.vimeoID, {
          attr: { width: 480, height: 270 }
      });
      
      // console.log(this.embedService.embed_vimeo(this.work.vimeoID));
    }
      
  }

  ngOnInit() {
  }
  
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
