import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-silver',
  templateUrl: './silver.component.html',
  styleUrls: ['./silver.component.scss']
})
export class SilverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
